.postFormBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* 세로 중앙 정렬 */
    width: 90%;
    padding: 2rem;
  }
  
  .post-form {
    width: 80%;
    flex-direction: column;
    border: 1px solid #acacac;
    padding: 2rem;
  }
  
  .postTitleInput, .userNameInput, .postContentInput {
    width: 100%;
    height: 2rem;
    margin-top: 1rem;
  }
  
  .postContentInput {
    height: 5rem;
  }
  
  .submitBtn {
    width: 5rem;
    height: 2rem;
    margin: 1rem;
  }
  
  .boardListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .fileInput {
    margin-top: 1rem;
  }
  .userNameInput {
    width: 10rem;
  }
  
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center; /* 버튼을 가운데로 배치합니다. */
  }
  
  .postingBtn {
    width: 4.5rem;
    height: 2.2rem;
    border-radius: 0.4rem;
    font-weight: bold;
    font-size: 0.7rem;
    border: none;
    background-color: rgb(70, 181, 189);
    color: #fff;
  }
  
  .postingBtn:hover {
    background-color: #444444;
    color: rgb(70, 181, 189);
    border: 2px solid rgb(70, 181, 189);
  }

  .closeBtn, .formSubmitBtn {
    width: 5.5rem;
    height: 2.2rem;
    border-radius: 0.4rem;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    background-color: rgb(99, 99, 99);
    color: #fff;
    margin: 1rem;

  }

